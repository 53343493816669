import React, { useEffect, useState } from "react";
import { Input, Textarea, Box, HStack, VStack, Button } from "@chakra-ui/react";
import { countries } from "../../../config/countries";

const Billing = ({ setDetails, details }) => {
  const { address, company, pobox, city, country, vat } = details.billing;
  const [billing, setBilling] = useState(false);
  useEffect(() => {
    if (
      address === "" &&
      company === "" &&
      pobox === "" &&
      city === "" &&
      country === "" &&
      vat === "" &&
      billing
    ) {
      setDetails({
        ...details,
        billing: {
          ...details.billing,
          address: details.personalDetails.address,
          company: "",
          pobox: details.personalDetails.pobox,
          city: details.personalDetails.city,
          country: details.personalDetails.country,
          vat: details.personalDetails.vat,
        },
      });
    } else if (!billing) {
      setDetails({
        ...details,
        billing: {
          address: "",
          company: "",
          pobox: "",
          city: "",
          country: "",
          vat: "",
        },
      });
    }
  }, [address, company, pobox, city, country, vat, billing]);

  const handleChange = (e) => {
    setDetails({
      ...details,
      billing: {
        ...details.billing,
        [e.target.name]: e.target.value,
      },
    });
  };
  return (
    <Box width={"100%"}>
      {billing && (
        <VStack>
          <HStack>
            <Box width={"50%"}>
              <label>Empresa</label>
              <Input
                value={company}
                name={"company"}
                onChange={handleChange}
                placeholder="Empresa"
              />
            </Box>
            <Box width={"50%"}>
              <label>NIF</label>
              <Input
                value={vat}
                name={"vat"}
                onChange={handleChange}
                placeholder="NIF"
              />
            </Box>
          </HStack>
          <HStack>
            <Box width={"70%"}>
              <label>Morada</label>
              <Input
                value={address}
                name={"address"}
                onChange={handleChange}
                placeholder="Morada"
              />
            </Box>
            <Box width={"30%"}>
              <label>Codigo Postal</label>
              <Input
                value={pobox}
                name={"pobox"}
                onChange={handleChange}
                placeholder="1000-010"
              />
            </Box>
          </HStack>
          <HStack>
            <Box width={"50%"}>
              <label>Cidade</label>
              <Input
                value={city}
                name={"city"}
                onChange={handleChange}
                placeholder="Cidade"
              />
            </Box>
            <Box width={"50%"}>
              <label>País</label>
              <Input
                value={country}
                name={"country"}
                onChange={handleChange}
                list="country"
                placeholder={"Selecione um pais"}
              />
              <datalist id="country">
                {countries &&
                  countries.map(({ name, code }) => (
                    <option key={code} value={name} />
                  ))}
              </datalist>
            </Box>
          </HStack>
        </VStack>
      )}
      <Button
        onClick={() => setBilling(!billing)}
        width={"100%"}
        color={"green"}
        mt={"1em"}
      >
        {billing && "Não preciso de contribuinte na fatura"}
        {!billing && "Quero contribuinte na fatura"}
      </Button>
    </Box>
  );
};

export default Billing;
