import { Box, HStack, Input } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
// import { setRemoteCall } from "remote";
import dayjs from "dayjs";
import "dayjs/locale/pt";
import isBetween from "dayjs/plugin/isBetween";
import isoWeek from "dayjs/plugin/isoWeek";
import "react-calendar/dist/Calendar.css";
import { BsCheckLg } from "react-icons/bs";
import "./custom.scss";
import TimePicker from "./TimePicker";
dayjs.extend(isBetween);
dayjs.extend(isoWeek);

const CalendarPicker = (props) => {
  //lets get the availability for this activity
  const [value, setValue] = useState(
    [props?.dates?.startDate, props?.dates?.endDate] || null
  );
  // const [dateEnd, setDateEnd] = useState(props.dateEnd || new Date());
  const [availability, setAvailability] = useState([]);
  const [fullDaySelect, setFullDaySelect] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [toogleCalendarView, setToogleCalendarView] = useState(false);
  const [timeStart, setTimeStart] = useState(
    props?.dates?.startDate
      ? dayjs(props?.dates?.startDate).format("HH:mm")
      : null
  );
  const [timeEnd, setTimeEnd] = useState(
    props?.dates?.endDate ? dayjs(props?.dates?.endDate).format("HH:mm") : null
  );

  //update on change
  // useEffect(() => {
  //   setValue(props.date || new Date());
  //   setDateEnd(props.dateEnd || new Date());
  // }, [props.date, props.dateEnd]);
  const fullDay = props.activityData && props.activityData.fullDay;

  //update on date change

  /**
   * Get activity data
   */
  useEffect(() => {
    async function fetchData() {
      try {
        if (!props.activity) throw new Error("no id defined");

        //let get the availability
        const availabilityData = await axios.get(
          `booking/availability/${props.activity}`
        );
        if (!availabilityData.data) throw new Error("no data");
        setAvailability(availabilityData.data);

        //let get the bookings
        const bookingsData = await axios.get(
          `booking/bookings/${props.activity}`
        );
        if (!bookingsData.data) throw new Error("no data");
        setBookings(bookingsData?.data?.filter((b) => b._id !== props.bookId));

        //let get the bookings
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [props.activity]);

  // useEffect(() => {
  //   // setRemoteCall(
  //   //   "getAvailabilityByActivity",
  //   //   { id: props.activity },
  //   //   null,
  //   //   (data) => {
  //   //     setAvailability(data);
  //   //   }
  //   // );
  //   // setRemoteCall(
  //   //   "getBookingsByActivity",
  //   //   { id: props.activity },
  //   //   null,
  //   //   (data) => {
  //   //     setBookings(data);
  //   //   }
  //   // );
  // }, [props.activity]);

  /**
   * Workout availability for a single date
   * @returns
   */
  const workAvailability = (date) => {
    const isUnique = props.activityData && props.activityData.uniqueReservation;
    let slots = { totalDaySlots: 0 };
    if (availability && availability.length > 0) {
      const workableDay = dayjs(date);
      availability
        .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
        .filter((a) => {
          //filter availability by date
          const startDate = dayjs(a.startDate);
          const endDate = dayjs(a.endDate);
          return workableDay.isBetween(startDate, endDate, "day", "[]");
        })
        .map((a, i) => {
          //only work on the first one
          if (i > 0) return null;
          //lets check if its the correct day
          if (a.weekDays) {
            const thisWeekDay = workableDay.isoWeekday();
            if (!a.weekDays[thisWeekDay]) {
              return null;
            }
          }
          //lets check if slots are available
          if (
            (!props.seats && props.seats !== 0) ||
            (props.seats && a.totalDaySlots < props.seats)
          )
            return null;
          //lets check if the date is between the start and end date
          if (
            workableDay.isBetween(dayjs(a.startDate), dayjs(a.endDate), "day")
          ) {
            slots = { ...a, initialTotalSlots: a.totalDaySlots };
            if (bookings) {
              bookings.map((b) => {
                if (
                  b.status !== 6 &&
                  b.status !== 8 &&
                  b.status !== 7 &&
                  b.status !== 4 &&
                  workableDay.isBetween(
                    dayjs(b.date),
                    dayjs(b.dateEnd),
                    "day",
                    "[]"
                  )
                ) {
                  if (isUnique) {
                    slots = {
                      ...slots,
                      totalDaySlots: 0,
                    };
                  } else {
                    slots = {
                      ...slots,
                      totalDaySlots: slots.totalDaySlots - b.seats,
                    };
                  }
                }
              });
            }
          }
        });
    }
    return slots;
  };

  /**
   * Workout availability per dates
   * dayjs loop days between two days
   * @returns
   */
  const getAvailabilityTwoDates = (days) => {
    const amountOfDays = dayjs(days[1]).diff(dayjs(days[0]), "days");
    let i = 0;
    let isHoverDisabled = false;
    while (amountOfDays > i) {
      i++;
      const currDay = dayjs(days[0]).add(i, "days");
      const isDisabled = getDisableDates({ date: currDay });
      if (!isHoverDisabled && isDisabled) {
        isHoverDisabled = true;
      }
    }
    if (isHoverDisabled) {
      setValue([]);
    }
  };

  /**
   * Workout availability per dates
   * @returns
   */
  const getAvailabilityDates = ({ activeStartDate, date, view }) => {
    let slots = workAvailability(date);
    let checked = false;
    if (fullDaySelect.find((d) => dayjs(d).isSame(dayjs(date), "day"))) {
      checked = true;
    }
    return (
      view === "month" && (
        <Box
          className="slots-text"
          fontSize={".7em"}
          background={"#4b8a2d"}
          fontWeight={"bold"}
          textAlign={"center"}
          color={"#fff"}
          borderRadius={"10px"}
        >
          {checked && (
            <Box m={"0 auto"} w={"10px"} padding={".5em 0"}>
              <BsCheckLg width={"10px"} />
            </Box>
          )}
          {/* {slots.totalDaySlots || 0} */}
        </Box>
      )
    );
  };

  /**
   * Workout disable dates
   * @returns
   */
  const getDisableDates = ({ activeStartDate, date, view }) => {
    if (dayjs(date).isBefore(new Date())) return true;
    let slots = workAvailability(date);
    return !slots || !slots.totalDaySlots;
  };

  // const setDateValues = (v) => {
  //   let slots = workAvailability(v);
  //   if (!fullDay) {
  //     setValue(v);
  //     setToogleCalendarView(true);
  //   } else {
  //     let newVals = [...fullDaySelect];

  //     if (newVals.find((d) => dayjs(d).isSame(dayjs(v), "day"))) {
  //       newVals = newVals.filter((d) => !dayjs(d).isSame(dayjs(v), "day"));
  //     } else {
  //       newVals.push(v);
  //     }

  //     let startDate;
  //     let endDate;
  //     if (newVals.length > 0) {
  //       newVals.map((v) => {
  //         if (!startDate || dayjs(v).isBefore(dayjs(startDate))) {
  //           startDate = v;
  //         }
  //         if (!endDate || dayjs(v).isAfter(dayjs(endDate))) {
  //           endDate = v;
  //         }
  //       });
  //     }

  //     //   bookedDate={props.date}
  //     //   bookedEnd={props.dateEnd}
  //     //   //this is where we save the new date
  //     props.onChange("setDates", {
  //       date: startDate,
  //       dateEnd: endDate,
  //       dateArray: newVals,
  //     });

  //     setFullDaySelect(newVals); //unique vals
  //   }
  // };
  /**
   * Render the calendar
   * @returns
   */
  return !toogleCalendarView ? (
    <>
      <Calendar
        onChange={(val) => {
          if (fullDay) {
            getAvailabilityTwoDates(val);
            let startPlaceholder = val[0];
            let endPlaceholder = val[1] || val[0];
            if (timeStart) {
              const sHourMinute = timeStart.split(":");
              console.log(sHourMinute);
              startPlaceholder = dayjs(startPlaceholder)
                .set("hour", sHourMinute[0])
                .set("minute", sHourMinute[1])
                .toDate();
            }

            if (timeEnd) {
              const eHourMinute = timeEnd.split(":");
              endPlaceholder = dayjs(endPlaceholder)
                .set("hour", eHourMinute[0])
                .set("minute", eHourMinute[1])
                .toDate();
            }
            props.onChange("setDates", {
              date: startPlaceholder,
              dateEnd: endPlaceholder,
            });

            setValue([startPlaceholder, endPlaceholder]);
          } else {
            setValue(val);
          }
        }}
        onClickDay={(v) => {
          if (!fullDay) {
            setValue(v);
            setToogleCalendarView(true);
          }
        }}
        allowPartialRange={true}
        value={value}
        selectRange={fullDay}
        returnValue={fullDay ? "range" : "start"}
        defaultActiveStartDate={props?.dates?.startDate || new Date()}
        minDate={dayjs().toDate()}
        maxDate={dayjs().add(1, "year").toDate()}
        tileContent={getAvailabilityDates}
        tileDisabled={getDisableDates}
      />
      {fullDay && (
        <HStack m={".6em 0"}>
          <Box w={"50%"}>
            <label>Hora de entrada</label>
            <Input
              value={timeStart}
              name={"timeStart"}
              type={"time"}
              onChange={(e) => {
                //update timeEnd, value and propsOnChange
                const hourMinute = e.target.value.split(":");
                if (value && hourMinute && hourMinute.length > 1) {
                  const startPlaceholder = dayjs(value[0])
                    .set("hour", hourMinute[0])
                    .set("minute", hourMinute[1])
                    .toDate();
                  const endPlaceholder = value[1] || value[0];
                  props.onChange("setDates", {
                    date: startPlaceholder,
                    dateEnd: endPlaceholder,
                  });
                  setValue([startPlaceholder, endPlaceholder]);
                }
                setTimeStart(e.target.value);
              }}
              placeholder="Hora de entrada"
            />
          </Box>
          <Box w={"50%"}>
            <label>Hora de saída</label>
            <Input
              value={timeEnd}
              name={"timeEnd"}
              type={"time"}
              onChange={(e) => {
                //update timeEnd, value and propsOnChange
                const hourMinute = e.target.value.split(":");
                if (value && hourMinute && hourMinute.length > 1) {
                  const startPlaceholder = value[0];
                  const endPlaceholder =
                    dayjs(value[1])
                      .set("hour", hourMinute[0])
                      .set("minute", hourMinute[1])
                      .toDate() ||
                    dayjs(value[0])
                      .set("hour", hourMinute[0])
                      .set("minute", hourMinute[1])
                      .toDate();
                  props.onChange("setDates", {
                    date: startPlaceholder,
                    dateEnd: endPlaceholder,
                  });
                  setValue([startPlaceholder, endPlaceholder]);
                }
                setTimeEnd(e.target.value);
              }}
              //  onChange={handleChange}
              placeholder="Hora de saída"
            />
          </Box>
        </HStack>
      )}
    </>
  ) : (
    <TimePicker
      props={props}
      onChange={props.onChange}
      bookedDate={props?.dates?.startDate}
      bookedEnd={props?.dates?.endDate}
      bookingId={props._id}
      value={value}
      bookings={bookings}
      availability={availability}
      workAvailability={workAvailability}
      toogleCalendarView={() => setToogleCalendarView(false)}
    />
  );
};

export default CalendarPicker;
