import React from "react";
import { Input, Box, HStack, VStack } from "@chakra-ui/react";
import { countries } from "../../../config/countries";
const PersonalDetails = ({ setDetails, details }) => {
  const {
    name,
    surname,
    city,
    local,
    institution,
    vat,
    country,
    pobox,
    address,
  } = details.personalDetails;

  const handleChange = (e) => {
    setDetails({
      ...details,
      personalDetails: {
        ...details.personalDetails,
        [e.target.name]: e.target.value,
      },
    });
  };
  return (
    <Box width={"100%"}>
      <VStack>
        <Box width={"100%"}>
          <label>Instituição</label>
          <Input
            value={institution}
            name={"institution"}
            onChange={handleChange}
            placeholder="Nome da Instituição"
          />
        </Box>
        <HStack>
          <Box width={"100%"}>
            <label>
              Nome <span>*</span>
            </label>
            <Input
              value={name}
              name={"name"}
              onChange={handleChange}
              placeholder="Nome"
            />
          </Box>
          <Box width={"100%"}>
            <label>
              Sobrenome <span>*</span>
            </label>
            <Input
              value={surname}
              name={"surname"}
              onChange={handleChange}
              placeholder="Sobre Nome"
            />
          </Box>
        </HStack>
        <Box width={"100%"}>
          <label>
            NIF <span>*</span>
          </label>
          <Input
            value={vat}
            name={"vat"}
            onChange={handleChange}
            placeholder="NIF"
          />
        </Box>
        <HStack>
          <Box width={"70%"}>
            <label>Morada</label>
            <Input
              value={address}
              name={"address"}
              onChange={handleChange}
              placeholder="Morada"
            />
          </Box>
          <Box width={"30%"}>
            <label>Codigo Postal</label>
            <Input
              value={pobox}
              name={"pobox"}
              onChange={handleChange}
              placeholder="1000-010"
            />
          </Box>
        </HStack>
        <HStack>
          <Box width={"50%"}>
            <label>
              Cidade <span>*</span>
            </label>
            <Input
              value={city}
              name={"city"}
              onChange={handleChange}
              placeholder="Cidade"
            />
          </Box>
          <Box width={"50%"}>
            <label>
              Local <span>*</span>
            </label>
            <Input
              value={local}
              name={"local"}
              onChange={handleChange}
              placeholder="Localidade"
            />
          </Box>
          <Box width={"50%"}>
            <label>
              País <span>*</span>
            </label>
            <Input
              value={country}
              name={"country"}
              onChange={handleChange}
              list="country"
              placeholder={"País"}
            />
            <datalist id="country">
              {countries &&
                countries.map(({ name, code }) => (
                  <option key={code} value={name} />
                ))}
            </datalist>
          </Box>
        </HStack>
      </VStack>
    </Box>
  );
};

export default PersonalDetails;
