import React from "react";
import { Input, Textarea, Box, HStack, VStack } from "@chakra-ui/react";

const Contacts = ({ setDetails, details }) => {
  const { email, phone, comments } = details.contacts;

  const handleChange = (e) => {
    setDetails({
      ...details,
      contacts: {
        ...details.contacts,
        [e.target.name]: e.target.value,
      },
    });
  };
  return (
    <Box width={"100%"}>
      <VStack>
        <HStack>
          <Box width={"100%"}>
            <label>
              Email <span>*</span>
            </label>
            <Input
              value={email}
              name={"email"}
              onChange={handleChange}
              placeholder="email@domain.pt"
            />
          </Box>
          <Box width={"100%"}>
            <label>
              Telefone <span>*</span>
            </label>
            <Input
              value={phone}
              name={"phone"}
              onChange={handleChange}
              placeholder="+351 912 345 678"
            />
          </Box>
        </HStack>

        <Box width={"100%"}>
          <label>Comentários</label>
          <Textarea
            name={"comments"}
            value={comments}
            onChange={handleChange}
            placeholder="Informações adicionais à reserva"
          />
        </Box>
      </VStack>
    </Box>
  );
};

export default Contacts;
