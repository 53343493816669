import { Box, useToast, Progress, Button } from "@chakra-ui/react";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import { setGlobalState, getGlobalState, useGlobalState } from "../store";

import Activity from "../components/Activity/Activity";
import NoActivity from "../components/Activity/NoActivity";
import Thankyou from "../components/Activity/Thankyou";
import Card from "../components/Card/Card";
import ContentSide from "../components/ContentSide/ContentSide";

//Forms
import Billing from "../components/Forms/Billing/Billing";
import Contacts from "../components/Forms/Contacts/Contacts";
import DatePicker from "../components/Forms/DatePicker/DatePicker";
import Navigation from "../components/Forms/Navigation/Navigation";
import Participants from "../components/Forms/Participants/Participants";
import PersonalDetails from "../components/Forms/PersonalDetails/PersonalDetails";
import Summary from "../components/Forms/Summary/Summary";

const Main = (props) => {
  let { id, bookid } = useParams();
  const toast = useToast();
  /**
   * State set
   */
  const [details, setDetails] = useState({
    activity: id,
    bookId: null,
    personalDetails: {
      name: "",
      vat: "",
      surname: "",
      address: "",
      city: "",
      local: "",
      country: "",
      institution: "",
      pobox: "",
    },
    participants: { slots: "", list: [] },
    schedule: {
      startDate: "",
      endDate: "",
      dateArray: [],
    },
    billing: {
      address: "",
      company: "",
      country: "",
      pobox: "",
      city: "",
      vat: "",
    },
    contacts: {
      email: "",
      phone: "",
      comments: "",
    },
  });

  const [activity, setActivity] = useState({
    title: "Nome da Atividade",
    location: "Local da Atividade",
    description:
      "Tot vocant extremum gravis postulant video optimus potuimus harum erroribus dicent genus eas ducimus rectas. Tot vocant eant video optimus potuimus harum erroribus dicent genus eas ducimus rectas",
  });
  const [loggedIn, setLoggedIn] = useState(null);
  const [step, setCurrStep] = useState(0);
  const [saved, setSaved] = useState(false);
  const [saving, setSaving] = useState(false);
  //loginMycascais
  useEffect(() => {
    async function getLogin() {
      if (!id && !bookid) return;
      //handle login
      if (window.MyCascaisCookie && !loggedIn) {
        try {
          const response = await axios.post(`/auth/mycascais/`, {
            tid: window.MyCascaisCookie,
          });
          if (response.data && response.data.token) {
            const userData = response.data;
            setLoggedIn(userData);
            setDetails({
              ...details,
              personalDetails: {
                institution: userData.institution,
                name: userData.firstName,
                vat: userData.vat,
                surname: userData.lastName,
                address: userData.address,
                city: userData.city,
                local: userData.local,
                country: userData.country,
                pobox: userData.poBox,
              },
              contacts: {
                ...details.contacts,
                email: userData.email,
                phone: userData.phone,
              },
            });
          } else if (response?.data?.myCascais) {
            setLoggedIn({
              status: "New user",
              myCascais: response?.data?.myCascais,
            });
          } else {
            loginMyCascais();
          }

          //handle logout
        } catch (error) {
          console.log(error);
          loginMyCascais();
          setLoggedIn(null);
        }
      } else if (!loggedIn) {
        loginMyCascais();
      }
    }
    getLogin();
    return () => {};
  }, [loggedIn, window.MyCascaisCookie]);
  /**
   * Submit reservation
   */

  const submitReservation = async () => {
    try {
      setSaving(true);
      const setData = await axios.post(`booking/gtn`, {
        ...details,
        myCascais: loggedIn.myCascais,
      });
      if (setData.data) {
        toast({
          title: "Reserva registada com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setSaved(true);
      } else {
        toast({
          title:
            "Aconteceu um erro a salvar a sua reserva, por-favor tente novamente",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
      setSaving(false);
    } catch (error) {
      setSaving(false);
      toast({
        title:
          "Aconteceu um erro a salvar a sua reserva, por-favor tente novamente",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  /**
   * Get activity data
   */
  useEffect(() => {
    async function fetchData() {
      try {
        if (!id) throw new Error("no id defined");
        const activityData = await axios.get(`booking/activity/${id}`);
        if (!activityData.data) throw new Error("no data");
        setActivity(activityData.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
    return () => {};
  }, [id]);

  /**
   * Get booking data
   * @param {*} bookid
   */
  useEffect(() => {
    async function fetchData() {
      try {
        if (details.bookId) return;
        setDetails({ ...details, bookId: bookid });
        if (!bookid) throw new Error("no id defined");
        const bookingData = await axios.get(`booking/${bookid}`);
        if (!bookingData.data) throw new Error("no data");
        const bookingDetails = bookingData.data.data;
        setDetails({
          ...details,
          activity: bookingData?.data?.data?.activity?._id || "",
          personalDetails: {
            name: bookingDetails?.owner?.firstName || "",
            vat: bookingDetails?.owner?.vat || "",
            surname: bookingDetails?.owner?.lastName || "",
            address: bookingDetails?.owner?.address || "",
            city: bookingDetails?.owner?.city || "",
            local: bookingDetails?.owner?.local || "",
            country: bookingDetails?.owner?.country || "",
            institution: bookingDetails?.owner?.institution || "",
            pobox: bookingDetails?.owner?.poBox || "",
          },
          participants: {
            slots: bookingDetails?.seats || "",
            list: bookingDetails?.participants?.list || [],
          },
          billing: {
            ...(bookingDetails?.billing || {}),
          },
          contacts: {
            email: bookingDetails?.owner?.email || "",
            phone: bookingDetails?.owner?.phone || "",
            comments: "",
          },
          schedule: {
            startDate: dayjs(bookingDetails?.date).toDate() || "",
            endDate: dayjs(bookingDetails?.dateEnd).toDate() || "",
            dateArray: bookingDetails?.schedule?.dateArray,
          },
          bookId: bookid,
          status: bookingDetails?.status,
        });
        setActivity(bookingData?.data?.data?.activity || activity);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
    return () => {};
  }, [bookid]);
  /**
   * check mandatory fields before continue
   * @returns
   */
  const setStep = (val) => {
    //if we go back
    if (val < step) {
      setCurrStep(val);
      return true;
    }
    //if forward
    if (step === 0) {
      if (
        details.personalDetails.name === "" ||
        !details.personalDetails.local ||
        details.personalDetails.local === "" ||
        details.personalDetails.surname === "" ||
        details.personalDetails.vat === ""
        // details.personalDetails.address === "" ||
        // details.personalDetails.pobox === ""
      ) {
        toast({
          title: "Preencha todos os campos obrigatórios",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        setCurrStep(val);
      }
    } else if (step === 1) {
      if (
        !details.participants.slots ||
        details.participants.slots === "" ||
        Number(details.participants.slots) < 1
      ) {
        toast({
          title: "Preencha todos os campos obrigatórios",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        setCurrStep(val);
      }
    } else if (step === 3) {
      if (details.contacts.email === "" || details.contacts.phone === "") {
        toast({
          title: "Preencha todos os campos obrigatórios",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        setCurrStep(val);
      }
    } else if (step === 2) {
      if (
        details.schedule.startDate === "" ||
        details.schedule.endDate === ""
      ) {
        toast({
          title: "Tem de escolher uma data",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        setCurrStep(val);
      }
    } else if (step === 3) {
      if (details.contacts.email === "" || details.contacts.phone === "") {
        toast({
          title: "Preencha todos os campos obrigatórios",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        setCurrStep(val);
      }
    } else if (step === 4) {
      // if (
      //   details.billing.address === "" ||
      //   details.billing.pobox === "" ||
      //   details.billing.city === "" ||
      //   details.billing.country === "" ||
      //   details.billing.vat === ""
      // ) {
      //   toast({
      //     title: "Preencha todos os campos obrigatórios",
      //     status: "error",
      //     duration: 9000,
      //     isClosable: true,
      //   });
      // } else {
      //   setCurrStep(val);
      // }
      setCurrStep(val);
    }
  };
  /**
   * Switch between form steps
   * @returns
   */
  const formStep = () => {
    //pendente or confirmada can only change dados de faturaçao
    if (details?.status === 1) {
      const bookingStepsFact = {
        0: (
          <>
            <Billing details={details} setDetails={setDetails} />
            <Navigation step={step} setStep={setStep}>
              Confimar Dados
            </Navigation>
          </>
        ),
        1: (
          <>
            <Summary details={details} />
            <Navigation
              step={step}
              setStep={setStep}
              onClick={() => !saving && submitReservation()}
            >
              Enviar Reserva
            </Navigation>
          </>
        ),
      };

      return bookingStepsFact;
    } else if (details?.status === 12) {
      //pendente can chnge participants and faturacao
      const bookingStepsPendente = {
        0: (
          <>
            <Participants details={details} setDetails={setDetails} />
            <Navigation step={step} setStep={setStep}>
              Escolher Data
            </Navigation>
          </>
        ),
        1: (
          <>
            <DatePicker
              bookId={details.bookId}
              seats={details.participants.slots}
              dates={details.schedule}
              activity={details.activity}
              activityData={activity}
              details={details}
              setDetails={setDetails}
            />
            <Navigation step={step} setStep={setStep}>
              Preencher contactos
            </Navigation>
          </>
        ),
        2: (
          <>
            <Billing details={details} setDetails={setDetails} />
            <Navigation step={step} setStep={setStep}>
              Confimar Dados
            </Navigation>
          </>
        ),
        3: (
          <>
            <Summary details={details} />
            <Navigation
              step={step}
              setStep={setStep}
              onClick={() => !saving && submitReservation()}
            >
              Enviar Reserva
            </Navigation>
          </>
        ),
      };

      return bookingStepsPendente;
    }

    //normal process
    return {
      0: (
        <>
          <PersonalDetails details={details} setDetails={setDetails} />
          <Navigation step={step} setStep={setStep}>
            Escolher Lugares
          </Navigation>
        </>
      ),
      1: (
        <>
          <Participants details={details} setDetails={setDetails} />
          <Navigation step={step} setStep={setStep}>
            Escolher Data
          </Navigation>
        </>
      ),
      2: (
        <>
          <DatePicker
            bookId={details.bookId}
            seats={details.participants.slots}
            dates={details.schedule}
            activity={details.activity}
            activityData={activity}
            details={details}
            setDetails={setDetails}
          />
          <Navigation step={step} setStep={setStep}>
            Preencher contactos
          </Navigation>
        </>
      ),
      3: (
        <>
          <Contacts details={details} setDetails={setDetails} />
          <Navigation step={step} setStep={setStep}>
            Dados de Faturação
          </Navigation>
        </>
      ),
      4: (
        <>
          <Billing details={details} setDetails={setDetails} />
          <Navigation step={step} setStep={setStep}>
            Confimar Dados
          </Navigation>
        </>
      ),
      5: (
        <>
          <Summary details={details} activity={activity} />
          <Navigation
            step={step}
            setStep={setStep}
            onClick={() => !saving && submitReservation()}
          >
            {saving ? "A salvar..." : "Enviar Reserva"}
          </Navigation>
        </>
      ),
    };
  };

  const loginMyCascais = async (e) => {
    try {
      if (!window.MyCascaisLogin)
        toast({
          title: "My cascais not available",
          status: "warning",
          position: "bottom",
        });
      window.location = window.MyCascaisLogin;
    } catch (e) {}
  };

  /**
   * show saved data
   */
  if (saved) {
    return (
      <ContentSide image={activity && activity.image}>
        <Thankyou />
      </ContentSide>
    );
  }
  /**
   * If no Activity is selected, show NoActivity component
   */
  if (!activity || (!id && !bookid)) {
    return <NoActivity />;
  }
  /**
   * If no Activity is selected, show NoActivity component
   */
  if (
    // !window.MyCascaisCookie &&
    !loggedIn
    // || (loggedIn && loggedIn.status !== "New user")
  ) {
    return (
      <ContentSide image={activity && activity.image}>
        <Box width={"100%"} padding={"0 2em"} textAlign={"center"}>
          <Box className="activity-overview">
            <h2>Autenticação MyCascais</h2>
          </Box>
        </Box>
        <Box width={"100%"}>
          <Card>
            <Box className={"local"} textAlign={"center"}>
              Para proceder com o pedido, é necessário autenticar-se com o
              serviço MyCascais.
              <br />
              <br />
              Dentro de alguns segundos será redirecionado para a página de
              autenticação...
            </Box>
            <Progress isIndeterminate />
            <Button
              onClick={(e) => loginMyCascais(e)}
              type="submit"
              bg="#000"
              w="100%"
              h="45"
              mb="5px"
              mt={"1em"}
              color="white"
              _hover={{
                bg: "teal.200",
              }}
              _active={{
                bg: "teal.400",
              }}
            >
              Entrar com My Cascais
            </Button>
          </Card>
        </Box>
      </ContentSide>
    );
  }

  /**
   * Lets render the form based on the step
   */
  return (
    <ContentSide image={activity && activity.image}>
      <Box width={"100%"} padding={"0 2em"}>
        <Progress step={step} setStep={setStep} />
        <Activity activity={activity} details={details} />
      </Box>
      <Box width={"100%"}>
        <Card>{formStep()[step]}</Card>
      </Box>
    </ContentSide>
  );
};

export default Main;
