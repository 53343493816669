import React from "react";
import { Box } from "@chakra-ui/react";
import Button from "../../Button/Button";
import IconBox from "../../IconBox/IconBox";
import { BiRightArrowAlt } from "react-icons/bi";

const Navigation = ({ step, setStep, children, onClick }) => {
  return (
    <Box w={"100%"} margin={"1em 0"}>
      <Button
        onClick={() => {
          onClick ? onClick() : setStep(step + 1);
        }}
        color={"green"}
      >
        {children}
        <IconBox icon={<BiRightArrowAlt />} />
      </Button>
      <Box
        mt={".5em"}
        cursor={"pointer"}
        fontSize={".8em"}
        textAlign={"center"}
        color={"#599331"}
        onClick={() => {
          // if (onClick) {
          //   onClick();
          // } else {
          if (step <= 1) {
            setStep(0);
          } else {
            setStep(step - 1);
          }
          // }
        }}
      >
        Voltar
      </Box>
    </Box>
  );
};

export default Navigation;
