import React from "react";
import dayjs from "dayjs";
import { Input, Box, HStack, VStack } from "@chakra-ui/react";
import Calendar from "../../Calendar/CalendarPicker";

const DatePicker = ({
  setDetails,
  details,
  activity,
  activityData,
  seats,
  dates,
  bookId,
}) => {
  const { name, surname, participants, pobox, address } =
    details.personalDetails;

  const setDates = (type, { date, dateEnd, dateArray }) => {
    setDetails({
      ...details,
      schedule: {
        ...details.schedule,
        startDate: date,
        endDate: dateEnd,
      },
    });
  };
  return (
    <Box width={"100%"}>
      <Calendar
        bookId={bookId}
        seats={seats}
        dates={dates}
        activity={activity}
        activityData={activityData}
        onChange={setDates}
      />
    </Box>
  );
};

export default DatePicker;
