//axios setup
import axios from "axios";
import config from "./config/config";

export const presistData = (type, data) => {
  try {
    if (typeof localStorage !== "undefined")
      localStorage[type] = JSON.stringify({ ...data, status: "" });
  } catch (error) {
    console.log(error);
  }
  return data;
};

export function jsonEquals(a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
}

export const getPresistData = (type, data) => {
  try {
    if (typeof localStorage !== "undefined" && localStorage[type]) {
      return JSON.parse(localStorage[type])[data];
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const configAxios = () => {
  axios.defaults.baseURL = `${config.apiURL}/api/`;

  axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";
  axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";
  axios.defaults.withCredentials = false; // for session keeping
  try {
    if (
      typeof localStorage === "undefined" &&
      !localStorage.getItem("gtnApp")
    ) {
      throw new Error("no user data");
    }

    const userData = JSON.parse(localStorage.getItem("gtnApp") || null);
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + userData.loggedIn.token;
  } catch (error) {
    console.log(localStorage.getItem("gtnApp"));
    axios.defaults.headers.common["Authorization"] = "";
  }
};
