import React from "react";
import "./styles.scss";
import { Badge } from "@chakra-ui/react";

import { Box } from "@chakra-ui/react";

const Activity = ({ activity, details }) => {
  const { title, location, address, description, price } = activity;
  const { participants } = details;
  let totalPrice = 0;
  if (participants?.slots && price) {
    totalPrice = participants.slots * price;
  }
  return (
    <Box className="activity-overview">
      <h2>{title}</h2>
      <p className="local">{address}</p>{" "}
      {participants?.slots && totalPrice > 0 ? (
        <Badge
          variant="subtle"
          mb={".6em"}
          borderRadius={"8px"}
          padding={".4em 1em"}
          background="#599331"
          color={"white"}
          mr={".5em"}
        >
          {totalPrice}€ - {participants.slots} vagas
        </Badge>
      ) : (
        ""
      )}
      {price ? (
        <Badge
          variant="subtle"
          mb={".6em"}
          borderRadius={"8px"}
          padding={".4em 1em"}
        >
          {price} € / pax
        </Badge>
      ) : (
        <Badge
          variant="subtle"
          m={".6em 0"}
          borderRadius={"8px"}
          padding={".4em 1em"}
          background="#599331"
          color={"white"}
          fontSize={".6em"}
        >
          Preço Sob consulta
        </Badge>
      )}
      <div className="description">{description}</div>{" "}
    </Box>
  );
};

export default Activity;
