import React from "react";
import { Box } from "@chakra-ui/react";
import ContentSide from "../ContentSide/ContentSide";
import Button from "../Button/Button";
import { HiOutlineDocumentAdd } from "react-icons/hi";
import IconBox from "../IconBox/IconBox";

const NoActivity = () => {
  return (
    <ContentSide image={null}>
      <Box width={"100%"} padding={"0 2em"}>
        <Box mb={"2em"}>
          <Box
            lineHeight={"1"}
            fontWeight={"bold"}
            color={"#599331"}
            fontSize={"2.5em"}
            mb={".5em"}
          >
            Nenhuma Atividade Selecionada
          </Box>
          <p>Tem de selecionar uma atividade primeiro antes de prosseguir</p>
        </Box>
        <Button
          color={"green"}
          onClick={() =>
            (window.location =
              "https://360.cascais.pt/pt/agenda/atividades-de-natureza-1")
          }
        >
          <Box>Escolher Atividade</Box>
          <IconBox icon={<HiOutlineDocumentAdd />} />
        </Button>
      </Box>
    </ContentSide>
  );
};

export default NoActivity;
