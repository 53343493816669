import React from "react";
import { Box } from "@chakra-ui/react";
import Card from "../Card/Card";
import ContentSide from "../ContentSide/ContentSide";
import Button from "../Button/Button";
import { publicURL } from "../../config/config";
import { HiOutlineDocumentAdd } from "react-icons/hi";
import IconBox from "../IconBox/IconBox";

const Thankyou = () => {
  return (
    <Box width={"100%"} padding={"0 2em"} mt={"10vh"}>
      <Box
        lineHeight={"1"}
        fontWeight={"bold"}
        color={"#599331"}
        fontSize={"2.5em"}
        mb={".5em"}
      >
        O seu pedido de reserva foi registado com sucesso!
      </Box>
      <Card>
        <Box mb={"2em"}>
          <p>
            Verifique o seu e-mail por favor. Em breve a nossa equipa entrará em
            contacto consigo para confirmar a reserva.
          </p>
        </Box>
        <Button
          color={"green"}
          onClick={() =>
            (window.location =
              "https://360.cascais.pt/pt/agenda/atividades-de-natureza-1")
          }
        >
          <Box>Escolher outra Atividade</Box>
          <IconBox icon={<HiOutlineDocumentAdd />} />
        </Button>
      </Card>
    </Box>
  );
};

export default Thankyou;
